export enum SciwormBiolinkModules {
  WORKFLOW = "modules/workflowbiolink",
  CELLS = "modules/cells",
  CELL_SOURCE = "modules/cell-source",
  CRYOPRESERVATION = "modules/cryopreservation",
  ADMIN = "modules/admin2",
  THAW = "modules/thaw",
  PASSAGE_2D = "modules/2D-passage",
  PASSAGE_3D = "modules/3D-passage",
  ASSAY_STAGE_DAY = "modules/assay-plan",
  BIOREACTOR = "modules/bioreactor-large-scale",
  BIOREACTOR_FEEDING = "modules/bioreactor-feeding",
  BIOREACTOR_FILL_INOCULATE = "modules/bioreactor-fill-inoculate",
  BIOREACTOR_MEDIUM_CHANGE = "modules/bioreactor-medium-change",
  BIOREACTOR_SAMPLING = "modules/bioreactor-sampling",
  BIOREACTOR_HARVEST = "modules/bioreactor-harvest",
  STORAGE_REQUEST = "modules/storage-request",
  LABDROID = "modules/labdroid",
}

function isSciwormBiolink(location: Location) {
  return Object.values(SciwormBiolinkModules).some(
    (module) => location.hash === `#/${module}` || location.hash.startsWith(`#/${module}/`),
  );
}

export function sciwormBiolinkLocation(location: Location) {
  return isSciwormBiolink(location);
}

export function notSciwormBiolinkLocation(location: Location) {
  return !isSciwormBiolink(location);
}
